import { graphql, Link, PageProps } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Button, Carousel, Col, Container, Row } from 'react-bootstrap'
import { Layout } from '../components/Layout'
import { NewsletterForm } from '../components/NewsletterForm'
import { Section } from '../components/Section'
import SEO from '../components/seo'
import ChevronDown from '../images/chevron-down.inline.svg'
import { HomeQuery, Must } from '../types'

const IndexPage = ({ data }: PageProps<Must<HomeQuery>>) => {
  const collections = data.allMarkdownRemark.edges
  return (
    <Layout overlay>
      <SEO />
      <div className="position-relative">
        <Carousel fade indicators={false} interval={3500} pause={false}>
          {collections.map(({ node }) => {
            const { id, frontmatter, fields } = node
            const img = frontmatter.image.childImageSharp.fluid

            return (
              <Carousel.Item key={id}>
                <Img
                  fluid={img}
                  alt={frontmatter.title}
                  className="vh-100 vw-100"
                  style={{ minHeight: '15rem' }}
                />
                <Carousel.Caption>
                  <Container className="d-lg-flex text-uppercase align-items-center justify-content-between">
                    <h2 className="mb-3 mb-lg-0">{frontmatter.title}</h2>
                    <Button size="lg" as={Link} to={fields.slug}>
                      View Collection
                    </Button>
                  </Container>
                </Carousel.Caption>
              </Carousel.Item>
            )
          })}
        </Carousel>
        <a className="arrow-down d-none d-lg-inline" href="#content">
          <ChevronDown />
        </a>
      </div>
      <Container
        fluid
        id="content"
        className="home py-4"
        style={{
          scrollMarginTop: '5.4375rem',
        }}
      >
        <Row noGutters className="small">
          <Col sm={6}>
            <Link to="/collections/">
              <figure>
                <Img {...data.collectionsPhoto.childImageSharp} alt="" />
                <figcaption style={{ opacity: 1 }}>View Collections</figcaption>
              </figure>
            </Link>
          </Col>
          <Col sm={6}>
            <Link to="/about/">
              <figure>
                <Img {...data.aboutPhoto.childImageSharp} alt="" />
                <figcaption style={{ opacity: 1 }}>
                  About Anita <span className="d-none d-md-inline">Grey</span>
                </figcaption>
              </figure>
            </Link>
          </Col>
        </Row>
      </Container>
      <Section variant="dark" className="text-white">
        <Container style={{ maxWidth: '600px' }}>
          <NewsletterForm />
        </Container>
      </Section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query Home {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/collections/" } }
      limit: 5
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    collectionsPhoto: file(
      absolutePath: { regex: "/01 Post-apocalyptic Wedding.jpg/" }
      childImageSharp: { id: { ne: null } }
    ) {
      id
      childImageSharp {
        fluid(maxHeight: 540, maxWidth: 910) {
          ...GatsbyImageSharpFluid
        }
      }
      absolutePath
    }

    aboutPhoto: file(
      absolutePath: { regex: "/anita-grey-headshot.jpg/" }
      childImageSharp: { id: { ne: null } }
    ) {
      id
      childImageSharp {
        fluid(maxHeight: 540, maxWidth: 910, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid
        }
      }
      absolutePath
    }
  }
`
